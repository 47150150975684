<template>
  <div class="text-center ma-2">
    <v-snackbar v-model="vModel">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "snackbar",
  props: ["text", "vModel"],
  data: () => ({
    snackbar: false,
  }),
};
</script>
